<template>
  <div class="auth__insales-login">
    <form action="" class="auth__form app-form auth__insales-login-form">
      <div class="app-form__header">Вход</div>
      <div class="app-form__content">
        <div class="app-form__field-list">
          <div v-for="(field, index) in formData.fields" :key="index" class="app-form__field-item">
            <div class="app-form__field-name">{{ field.title }}</div>
            <app-input
                v-model="field.value"
                :placeholder="field.placeholder"
                :name="field.name"
                :type="field.type"
                :class-name="'app-form__field'"
            />
          </div>
        </div>
        <app-button :value="'Отправить'" :type="'submit'" :class-name="'app-form__button'" />
      </div>
      <div class="app-form__description">
        Не получайтеся войти? Перейдите к <br> <router-link to="/auth/update-password">востановлению пороля</router-link> или <router-link to="/auth/create">регистрации</router-link>
      </div>
    </form>

    <div class="auth__insales-login-message">
      {{ message }}
    </div>
  </div>

</template>

<script>
import { getSearchParam } from '../../utils/index';
import { config } from '../../config/index';
import { PnnApi } from '../../api/pnn-api';
import * as crypto from 'crypto';
import appInput from "../../components/base-ui/app-input";
import appButton from "../../components/base-ui/app-button";

const MESSAGES = {
  ERROR: 'Ошибка автоматической авторизации. Пожалуйста, повторите попытку или авторизуйтесь через форму.',
  INVALID: 'Данная страница недоступна. Пожалуйста, перейдите на страницу авторизации.',
}


export default {
  name: 'insales-login',
  data: function () {
    return {
      formData: {
        title: 'Вход',
        fields: {
          email: {
            title: 'Id магазина',
            placeholder: 'Id магазина',
            type: 'number',
            name: 'shopId',
            value: ''
          },
          password: {
            title: 'Пароль',
            placeholder: 'Пароль',
            type: 'password',
            name: 'password',
            value: ''
          }
        }
      },
      message: 'Автоматическая авторизация. Пожалуйста, подождите...'
    }
  },
  components: {
    'app-input': appInput,
    'app-button': appButton,
  },
  methods: {
    autoLogin: async function(params) {
      const insalesShopUrl = localStorage.getItem('insales_shop_url');  localStorage.removeItem('insales_shop_url');
      const insalesLoginToken = localStorage.getItem('insales_login_token');  localStorage.removeItem('insales_login_token');
      const insalesShopId = localStorage.getItem('insales_shop_id');  localStorage.removeItem('insales_shop_id');

      if (! insalesShopUrl || ! insalesLoginToken && ! insalesShopId) {
        this.message = `${MESSAGES.ERROR} Отсутствует обязательный параметр`;
        return;
      }

      const accountData = await PnnApi.getAccountDataByInsalesId(insalesShopId);

      if (! accountData.insales_id) {
        this.message = `${MESSAGES.ERROR} Не удалось получить аккаунт`;
        return;
      }

      const token = crypto.createHash('md5').update(insalesLoginToken + params.user_email + decodeURI(params.user_name) + params.user_id + params.email_confirmed + accountData.api_password).digest('hex');

      if (token === params.token3) {
        // Пользователь действительно авторизован в insales

        await this.$store.dispatch('loginAccount', {
          login: insalesShopId,
          password: accountData.api_password
        })
          .then(res => {
            console.log('res', res);

            if (res.result === 'success') {
              this.$router.push({ path: '/'});
            }
          })
      } else {
        this.message = `${MESSAGES.ERROR} Ошибка сверки токена`;
        return;
      }
    },
    redirect: function (params) {
      if (! params.shop || ! params.insales_id || ! params.user_email || ! params.user_id) {
        this.message = MESSAGES.INVALID;
        return;
      }

      localStorage.setItem('insales_shop_url', params.shop)
      localStorage.setItem('insales_shop_id', params.insales_id)
      localStorage.setItem('insales_login_token', params.user_email + params.user_id)

      const insalesAutoLoginUrl = `//${params.shop}/admin/applications/${config.app.id}/login?token=${params.user_email + params.user_id}&login=${window.location.protocol + '//' + window.location.host}/auth/insales-auto-login`;
      window.location.href = insalesAutoLoginUrl;
    },

    login: async function () {
      const params = getSearchParam();

      if (params.token) {
        // Делаем автологие тк. пользователь авторизован в магазине
        this.autoLogin(params)
      } else {
        // Делаем редирект тк. нужно убедиться что пользователь авторизован
        this.redirect(params)
      }
    }
  },
  mounted() {
    this.login();
  }
}
</script>


<style lang="scss">
.auth {
  &__insales-login {
    position: relative;
  }
  &__insales-login-form {
    opacity: .2;
  }
  &__insales-login-message {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;

    background: rgba(255, 255, 255, .5);
  }
}
</style>
