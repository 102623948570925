export const getSearchParam = () => {
  const param = {};
  const queryString = window.location.href.split('?')[1];

  if (! queryString) {
    return {}
  }

  queryString.replace('?', '').split('&').forEach(item => {
    const itemArr = item.split('=');
    param[itemArr[0]] = itemArr[1].replace('%40', '@');
  })

  return param;
}

export const getSearchParamInObject = (obj, jsonParamsArray = []) => {
    const param = Object.keys(obj).map(key => {
           if (jsonParamsArray.includes(key)) {
                return `${key}=${JSON.stringify(obj[key])}`
           } else {
                return `${key}=${obj[key]}`
           }


    }).join('&')


    return window.encodeURI(param);
}

export const parsingJWT = (token) => {
  const tokenBody = token.split('.')[1];
  const base64 = tokenBody.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
